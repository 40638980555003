import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SecondaryLanding from "../../components/layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import BranchSearch from "../../components/branch-search/branch-search";
import WaFdBankStatesMap from "../../assets/wafd-bank-9-states-plain-map.svg";
import NoLinkCards from "../../components/card/no-link-cards";

const SpanishLocations = () => {
  const imageData = useStaticQuery(graphql`
    {
      checkingImage: file(relativePath: { eq: "cards/thumbnail-checking-030524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      savingsImage: file(relativePath: { eq: "cards/thumbnail-savings-030524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      mortgageImage: file(relativePath: { eq: "cards/thumbnail-home-loans-030524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Sucursales"
    }
  ];

  const SEOData = {
    title: "Sucursales bancarias | WaFd Bank - Uno de los mejores bancos",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Sucursales bancarias | WaFd Bank - Uno de los mejores bancos"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Todas nuestras sucursales bancarias están a su disposición. Comience a buscar los bancos más cercanos a usted y visite WaFd Bank hoy mismo."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/sucursales"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const threeCardsData = {
    sectionId: "locations-link-cards",
    sectionClass: "bg-white",
    cards: [
      {
        id: "locations-link-card-checking",
        title: "La Cuenta de Cheques Simple, Directa, e Inteligente",
        titleClass: "text-success",
        text: "Tu cuenta debería trabajar duro para tí. Desde herramientas estándar como Bill Pay hasta beneficios y recompensas, encontrarás una cuenta de cheques que se ajuste a tus necesidades. Además, ¡nuestras atractivas tarjetas de débito te ayudarán a gastar con estilo!",
        image: {
          imgVariable: imageData.checkingImage.childImageSharp.gatsbyImageData,
          altText: "Mujer usa su celular mientras está sentada con las piernas cruzadas en un sofá."
        },
        button: {
          id: "locations-link-card-checking-cta",
          text: "Comparar Cuentas",
          url: "/es/banca-personal/cuenta-de-cheques",
          class: "btn-primary btn-block",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "locations-link-card-savings",
        title: "Cuentas de Ahorro para Ayudarle a Prosperar",
        titleClass: "text-success",
        text: "Con una variedad de opciones de ahorro, tu dinero puede trabajar más para tí. Ofrecemos varias opciones para satisfacer tus necesidades con la flexibilidad que la vida requiere.",
        image: {
          imgVariable: imageData.savingsImage.childImageSharp.gatsbyImageData,
          altText: "Una madre y su hijo de vacaciones, estación de tren de Copenhagen."
        },
        button: {
          id: "locations-link-card-savings-cta",
          text: "Comparar Cuentas",
          url: "/es/banca-personal/cuentas-de-ahorro",
          class: "btn-primary btn-block",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      },
      {
        id: "locations-link-card-mortgage",
        title: "Préstamos Hipotecarios que se Adaptan a tu Vida",
        titleClass: "text-success",
        text: "Estés donde estés en la vida, un préstamo hipotecario debe adaptarse a tus necesidades. Con opciones para compradores de vivienda por primera vez, remodelación, construcción y más, nuestros oficiales de préstamos expertos están aquí para ayudarte.",
        image: {
          imgVariable: imageData.mortgageImage.childImageSharp.gatsbyImageData,
          altText: "Una feliz pareja sentada en su sillón, riendo."
        },
        button: {
          id: "locations-link-card-mortgage-cta",
          text: "Ver Más",
          url: "/es/banca-personal/prestamos-hipotecarios",
          class: "btn-primary btn-block",
          containerClass: "mt-auto w-100",
          showIcon: false
        }
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="branch-locations-section" className="container">
        <h1>Ubicaciones de WaFd Bank</h1>
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <img className="w-100" src={WaFdBankStatesMap} alt="State Selection Map" />
          </div>
          <div className="col-md-6 col-lg-9">
            <h3>
              Aunque WaFd Bank se extiende a lo largo de 9 estados, en nuestro corazón somos su banco local. Ya sea que
              esté buscando una hipoteca para la casa de sus sueños, servicios bancarios para su negocio o líneas de
              crédito con garantía hipotecaria, WaFd Bank está aquí para ayudarlo.
            </h3>
          </div>
        </div>
      </section>
      <BranchSearch isSpanish={true} />
      <NoLinkCards {...threeCardsData} />
    </SecondaryLanding>
  );
};

export default SpanishLocations;
